import React, { Component } from "react";
import BannerMail from "./BannerMail";
import MavTop from "./MavTop";
import Funcionalidades from "./Funcionalidades";
import $ from "jquery";
import top from "../../assets/img/top.svg";
import { Helmet } from "react-helmet";

export default class MavMail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      display: "",
      popDisplay: "none",
    };

    setTimeout(() => {
      if (localStorage.getItem("intent") === "1") {
        this.setState({
          display: "none",
        });
      }
    }, 10);
  }
  componentDidMount() {
    $(window).scrollTop(0);
    return false;
  }

  close = () => {
    this.setState({
      popDisplay: "none",
    });
  };
  render() {
    return (
      <div className="mavMail_wrapper">
        <Helmet>
          <script>
            {`
      function gtag_report_conversion(n) {
        return (
          gtag("event", "conversion", {
            send_to: "AW-933981825/VfrkCOL7i7QBEIHdrb0D",
            event_callback: function () {
              void 0 !== n && (window.location = n);
            },
          }),
          !1
        );
      }
          `}
          </script>
        </Helmet>
        <div
          className="intet_wrapper"
          style={{ display: this.state.popDisplay }}
        >
          <div className="close" onClick={this.close}>
            X
          </div>
          <div className="popup_intent_mail">
            <img src={top} alt="top" />
            <div className="content_wrapper">
              <div className="text_pop_wrapper">
                <h1>
                  Antes de ir embora, conheça um pouco mais da nossa ferramenta
                  de e-mail.
                </h1>
              </div>

              <div className="text_pop_wrapper_bottom">
                <p>
                  Clique aqui e assista a uma apresentação rápida dos nossos
                  recursos.
                </p>
              </div>
            </div>
            <div className="button_wrapper">
              <form
                method="POST"
                action="https://mav747.activehosted.com/proc.php"
                id="_form_9_"
                className="_form _form_9 _inline-form  _dark"
              >
                <input type="hidden" name="u" value="9" />
                <input type="hidden" name="f" value="9" />
                <input type="hidden" name="s" />
                <input type="hidden" name="c" value="0" />
                <input type="hidden" name="m" value="0" />
                <input type="hidden" name="act" value="sub" />
                <input type="hidden" name="v" value="2" />
                <div className="_form-content">
                  <div className="_form_element _x23540794 _full_width _clear"></div>
                  <div className="_form_element _x80386606 _full_width ">
                    <label className="_form-label">{/* NOME */}</label>
                    <div className="_field-wrapper">
                      <input
                        type="text"
                        name="fullname"
                        placeholder="Digite seu nome"
                      />
                    </div>
                  </div>
                  <div className="_form_element _x41245829 _full_width ">
                    <label className="_form-label">{/* E-MAIL* */}</label>
                    <div className="_field-wrapper">
                      <input
                        type="text"
                        name="email"
                        placeholder="Digite seu e-mail"
                        required
                      />
                    </div>
                  </div>
                  <div className="_button-wrapper _full_width">
                    <button
                      id="_form_9_submit "
                      className="_submit button_sim"
                      type="submit"
                    >
                      quero conhecer agora
                    </button>
                  </div>
                  <div className="_clear-element"></div>
                </div>
                <div className="_form-thank-you"></div>
                <div className="_form-branding"></div>
              </form>
            </div>
          </div>
        </div>
        <BannerMail />
        <MavTop />
        <Funcionalidades />
      </div>
    );
  }
}

import React from 'react';
import './scss/style.css';
import './scss/responsive.css';
import './App.css';
import Menu from './Components/Menu';
import Footer from './Components/Footer';
import Routes from './Routes';
import {BrowserRouter} from 'react-router-dom';
import Call from './Pages/Home/Call';


function App() {
  const getBasename = path => path.substr(0, path.lastIndexOf('/'));
  return (
    <div className="App">
      <BrowserRouter basename={getBasename(window.location.pathname)}>
        <Menu/>
        <div className="routes">
        <Routes/>
        </div>
        <Call/>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;

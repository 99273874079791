import React from "react";

import "./style.css";
import checked from "../../../assets/img/checked.svg";
import AnchorLink from "react-anchor-link-smooth-scroll";
function BannerMail() {
  return (
    <div className="bannermail_wrapper_b">
      <div className="banner_group">
        <div className="bannermail_container">
          <div className="title_b_b">
            Hospedagem profissional para sua empresa
          </div>
          <div className="subtitle_b">
            Hospedagem de e-mails e site para empresas que buscam segurança e
            simplicidade na web
          </div>
          <div className="subTitleSub">
            O Antispam é um recurso presente no plano de hospedagem de e-mails e
            site
          </div>
          <div className="button_container">
            Planos a partir de <span>R$9,90/mês</span>
            <button className="falar_button">Falar com especialista</button>
          </div>
        </div>

        <div className="funcionalidades_banner">
          <div className="title">Recursos exclusivos do do MAV Mail</div>

          <div className="boxes_container_banner">
            <AnchorLink href="#antispam" className="box_func_banner">
              <div className="circle">
                <img src={checked} alt="icon" />
              </div>

              <div className="">Antispam</div>
            </AnchorLink>

            <AnchorLink href="#auditoria" className="box_func_banner">
              <div className="circle">
                <img src={checked} alt="icon" />
              </div>
              <div className="">Arquivamento</div>
            </AnchorLink>

            <AnchorLink href="#controle" className="box_func_banner">
              <div className="circle">
                <img src={checked} alt="icon" />
              </div>
              <div className="">Controle de Acesso</div>
            </AnchorLink>

            <AnchorLink href="#recibo" className="box_func_banner">
              <div className="circle">
                <img src={checked} alt="icon" />
              </div>
              <div className="">Recibo de Entrega</div>
            </AnchorLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerMail;

import React, { Component } from 'react';
import BannerMail from './BannerMail';
import Funcionalidades from './Funcionalidades';
import $ from 'jquery'
import Propriedades from '../Antispam/Funcionalidades/Propriedades';
// import top from '../../assets/img/top.svg'

export default class MavMail extends Component {

  componentDidMount(){
  $(window).scrollTop(0);
  return false;

  }




  render() {
    return (
    <div className="mavMail_wrapper">

      <BannerMail/>
      {/* <MavTop/> */}
      <Funcionalidades/>
      <Propriedades/>
    </div>
    );
  }
}

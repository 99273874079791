import React, { Component } from "react";

import { Switch, Route } from "react-router-dom";
import Home from "../Pages/Home";
import MavMail from "../Pages/MavMail";
import Antispam from "../Pages/Antispam/";
import LGPD from "../Pages/LGPD";
import $ from "jquery";
import Smtp from "../Pages/Smtp";
import Vps from "../Pages/Vps";
import Revenda from "../Pages/Revenda";
import Mailc from "../Pages/Mailc";
import Error from "../Components/Error";
export default class Routes extends Component {
  componentDidMount() {
    $(window).scrollTop(0);
    return false;
  }
  render() {
    return (
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>

        <Route path="/mail">
          <MavMail />
        </Route>

        <Route path="/servicos-de-email">
          <Mailc />
        </Route>

        <Route path="/antispam">
          <Antispam />
        </Route>

        <Route path="/lgpd">
          <LGPD />
        </Route>

        <Route path="/smtp">
          <Smtp />
        </Route>

        <Route path="/vps">
          <Vps />
        </Route>

        <Route path="/revenda">
          <Revenda />
        </Route>
        
        <Route path="*">
          <Error />
        </Route>
      </Switch>
    );
  }
}

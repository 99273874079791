import React, { Component } from "react";

import database from "../../assets/img/database.svg";
import ssd from "../../assets/img/ssd.svg";
import bandwidth from "../../assets/img/bandwidth.svg";
import security from "../../assets/img/security.svg";
import windows from "../../assets/img/windows.svg";
import backup from "../../assets/img/backup.svg";
import server from "../../assets/img/server.svg";
import server_a from "../../assets/img/server_a.svg";
import layout from "../../assets/img/layout.svg";

export default class Vps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "Tstenado o texto",
    };
  }
  render() {
    return (
      <div className="vps_wrapper">
        <div className="home_vps_wrapper">
          <div className="animation_area"></div>
        </div>

        <div className="propriets_wrapper">
          <div className="title_defualt_vps">
            <h1>Características da VPS</h1>
            <div className="sub">
              <span />
              <p>Simples, eficaz e Confiável.</p> <span />
            </div>
          </div>

          <div className="box_prop_wrapper">
            <div className="box">
              <div className="icon_wrapper">
                <img src={bandwidth} width="50%" alt="databse" />
              </div>

              <div className="title_box">Largura de Banda</div>
              <p>100 Mbps - Tráfego ilimitado</p>
            </div>

            <div className="box">
              <div className="icon_wrapper">
                <img src={database} width="50%" alt="databse" />
              </div>

              <div className="title_box">Acesso Root </div>
              <p>
                IP: 1 IPv4 incluído + 1 IPv6 incluído (todas as portas abertas)
              </p>
            </div>

            <div className="box">
              <div className="icon_wrapper">
                <img src={security} width="50%" alt="databse" />
              </div>

              <div className="title_box">Anti-DDoS</div>
              <p>Proteção incluída</p>
            </div>
          </div>
        </div>

        <div className="optional_wrapper">
          <div className="title_defualt_vps">
            <h1>Opcionais</h1>
            <div className="sub">
              <span />
              <p>Simples, eficaz e Confiável.</p> <span />
            </div>
          </div>

          <div className="box_optional_wrapper">
            <div className="top_area_optional">
              <div className="box_icon_optional">
                <div className="icon_op">
                  <img src={backup} width="50%" alt="icon" />
                </div>
                <div className="desc">
                  <strong>Backup</strong>
                  <br />
                  Manual (Snapshot)
                </div>
              </div>

              <div className="box_icon_optional">
                <div className="icon_op">
                  <img src={server_a} width="50%" alt="icon" />
                </div>
                <div className="desc">
                  <strong>Espaço adicional</strong>
                  <br />
                  Discos adicionais
                </div>
              </div>

              <div className="box_icon_optional">
                <div className="icon_op">
                  <img src={windows} width="50%" alt="icon" />
                </div>
                <div className="desc">
                  <strong>Sistemas Operacionais</strong>
                  <br />
                  Linux ou Windows
                </div>
              </div>
            </div>

            <div className="bottom_area_optional">
              <div className="box_icon_optional">
                <div className="icon_op">
                  <img src={layout} width="50%" alt="icon" />
                </div>
                <div className="desc">
                  <strong>Plesk</strong>
                  <br />O Plesk é um painel de controle inovador e fácil de
                  usar.
                </div>
              </div>

              <div className="box_icon_optional">
                <div className="icon_op">
                  <img src={ssd} width="50%" alt="icon" />
                </div>
                <div className="desc">
                  <strong>Hospedagem SSD</strong>
                  <br />
                  Aumente o desempenho do seu site.
                </div>
              </div>

              <div className="box_icon_optional">
                <div className="icon_op">
                  <img src={server} width="50%" alt="icon" />
                </div>
                <div className="desc">
                  <strong>Ativação Imediata</strong>
                  <br />
                  Tenha um servidor rodando em poucos minutos!
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="divisa_text_wrapper">
          <div className="principal_text">
            Aumente o desempenho do seu site com a nossa Hospedagem SSD.
          </div>
          <p>Tenha um servidor rodando em poucos minutos!</p>
        </div>

        <div className="vantagens_wrapper">
          <div className="title_defualt_vps">
            <h1>Vantagens de um Servidor VPS SSD</h1>
            <div className="sub">
              <span />
              <p>Simples, eficaz e Confiável.</p> <span />
            </div>
          </div>

          <div className="vantagens_text_wrapper">
            O <strong>SSD usa memória flash para armazenar dados</strong>. Isso
            melhora significativamente a velocidade de leitura e gravação,
            resultando em melhor desempenho do servidor e na velocidade de
            carregamento do site.
          </div>
          <div className="vantagens_text_wrapper">
            Os <strong>SSDs</strong> são mais confiáveis em termos de eficiência
            e durabilidade.
          </div>
          <div className="vantagens_text_wrapper">
            O carregamento mais rápido de um site, afeta diretamente os
            resultados do mecanismo de pesquisa do Google e o SEO do seu site.{" "}
            <strong>Os sites hospedados com VPS SSD</strong>, são carregados de
            forma muito mais rápida, ajudando a melhorar o ranking da sua
            página.
          </div>
          <div className="btn_vps">Saiba mais</div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";

import img2 from "../../assets/img/png/hero.png";
import img3 from "../../assets/img/png/analyze.png";
import img4 from "../../assets/img/png/service-left.png";
import img5 from "../../assets/img/png/plan-image.png";
import img6 from "../../assets/img/png/revp.png";
import img7 from "../../assets/img/png/revp2.png";
import img8 from "../../assets/img/png/revp3.png";
import img9 from "../../assets/img/png/revp4.png";

export default class Revenda extends Component {
  render() {
    return (
      <div className="revenda_wrapper">
        <div className="banner_revenda">
          <div className="title_banner">Seja um parceiro MAV</div> <br></br>
          <div className="sub_title">
            {" "}
            Incorpore nossas soluções ao seu portfólio e gere mais lucro para a
            sua empresa!
          </div>
          <span />
        </div>
        <div className="propriedades_revanda">
          <div className="title_wrapper">
            <h1>Seja um parceiro MAV</h1>
            <p>Incorpore nossas soluções ao seu portfólio</p>
          </div>

          <div className="content_boxes_wrapper">
            <div className="box_content_revenda">
              <div className="left_area">
                <h1>Aumente sua receita</h1>
                <p>
                  A revenda de e-mail e hospedagem da MAV são ideais para
                  empresas que buscam novas oportunidades de negócios em sua
                  região de atuação. Comercialize produtos diferenciados com
                  ótima margem de lucro!
                </p>
              </div>
              <div className="right_area">
                <img src={img2} width="70%" alt="image1"></img>
              </div>
            </div>

            <div className="box_content_revenda reverse">
              <div className="left_area">
                <h1>Nosso produto, sua marca</h1>
                <p>
                  Utilize a nossa tecnologia de ponta para hospedar e-mails e
                  sites utilizando a sua própria marca. Todas as interfaces
                  visíveis aos seus clientes referentes à administração do
                  produto e do Webmail terão a sua identidade.
                </p>
              </div>
              <div className="right_area">
                <img src={img5} width="75%" alt="image2"></img>
              </div>
            </div>

            <div className="box_content_revenda">
              <div className="left_area">
                <h1>Sobre a MAV</h1>
                <p>
                  A MAV é uma empresa que desenvolve suas próprias tecnologias,
                  por isso, pode oferecer condições comerciais mais vantajosas
                  na revenda de servidor de e-mail corporativo. A rentabilidade
                  mínima é de 33%, podendo chegar a 80% em alguns casos.
                </p>
              </div>
              <div className="right_area">
                <img src={img6} width="75%" alt="image3"></img>
              </div>
            </div>

            <div className="box_content_revenda reverse">
              <div className="left_area">
                <h1>Conquiste novos mercados</h1>
                <p>
                  As soluções MAV atingem praticamente todos os segmentos de
                  mercado, ampliando a possibilidade de novos clientes. Os
                  diferenciais e benefícios exclusivos dão mais credibilidade e
                  competitividade em relação às soluções concorrentes,
                  facilitando a venda.
                </p>
              </div>
              <div className="right_area">
                <img src={img4} width="70%" alt="image4"></img>
              </div>
            </div>

            <div className="box_content_revenda">
              <div className="left_area">
                <h1>Diferenciais nos produtos</h1>
                <p>
                  MAV Supramail: Antispam com 99% de eficiência, backup/archive
                  dos e-mails, auditoria e monitoramento do tráfego, confirmação
                  de entrega dos e-mails, filtro por horário e certificado
                  gratuito.
                </p>
              </div>
              <div className="right_area">
                <img src={img3} width="70%" alt="image5"></img>
              </div>
            </div>

            <div className="box_content_revenda reverse">
              <div className="left_area">
                <h1>Parceiro tecnológico</h1>
                <p>
                  A MAV dedica 20 mil horas/ano no aprimoramento e criação de
                  produtos inovadores. É a única empresa brasileira que
                  desenvolve tecnologia própria de antispam e ferramentas para
                  gestão de e-mails corporativos.
                </p>
              </div>
              <div className="right_area">
                <img src={img7} width="75%" alt="image6"></img>
              </div>
            </div>

            <div className="box_content_revenda">
              <div className="left_area">
                <h1>Autonomia de precificação</h1>
                <p>
                  Com a revenda de email, nossas revendas têm liberdade na
                  precificação dos produtos, evitando o engessamento comum em
                  soluções estrangeiras. Você sempre saberá os valores que
                  praticamos para os clientes finais e poderá definir as
                  políticas comerciais a seu critério.
                </p>
              </div>
              <div className="right_area">
                <img src={img8} width="75%" alt="image7"></img>
              </div>
            </div>

            <div className="box_content_revenda reverse">
              <div className="left_area">
                <h1>Suporte técnico</h1>
                <p>
                  A sua empresa terá suporte técnico com pessoal especializado,
                  através de telefone, Skype e WhatsApp para dúvidas do dia a
                  dia. O produto mantém uma filosofia moderna e produtiva, na
                  qual o próprio cliente final consegue realizar as principais
                  tarefas pelo painel de controle.
                </p>
              </div>
              <div className="right_area">
                <img src={img9} width="75%" alt="image8"></img>
              </div>
            </div>
          </div>
        </div>

        <div className="bar_call">
          <div className="text_call">
            <h1>Fale com nossos consultores</h1>
          </div>
          <div className="button_area">
            <button className="btn_revenda">Saiba mais</button>
          </div>
        </div>
      </div>
    );
  }
}

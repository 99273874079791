import React, { Component } from "react";
import logo from "../../assets/img/logo_d2.png";
import $ from "jquery";
import { Link } from "react-router-dom";
import inte from '../../assets/img/integrations.svg'
export default class Menu extends Component {
  componentDidMount() {
    $(document).ready(function ($) {
      $(window).scroll(function () {
        if ($(window).scrollTop() >= 20) {
          $(".menu_wrapper").addClass("back");
        } else {
          $(".menu_wrapper").removeClass("back");
        }
      });

      $(".hamburguer-bt").click(function () {
        $(this).toggleClass("on");
      });

      $(".hamburguer-bt").click(() => {
        $(".menu_full").slideToggle(400);
      });

      $(".nav_wrapper_menu_full ul li").click(() => {
        $(".menu_full").slideToggle(400);
        $(".hamburguer-bt").toggleClass("on");
      });
    });
  }
  render() {
    return (
      <>
        <div className="menu_wrapper">
          <div className="logo_wrapper">
            <Link to={"/"}>
              <img src={logo} alt="mail" />
            </Link>
          </div>
          <div className="nav_wrapper">
            <ul>
              <Link to={"/"}>
                <li>Inicio</li>
              </Link>
              <Link to={"/mail"}>
                <li>E-mail Profissional</li>
              </Link>
              <Link to={"/antispam"}>
                <li>AntiSpam</li>
              </Link>
              <Link to={"/smtp"}>
                <li>SMTP</li>
              </Link>
              <Link to={"/vps"}>
                <li>Vps</li>
              </Link>
              <Link to={"/revenda"}>
                <li className="lgpd_nav">Revenda</li>
              </Link>
              <div className="divisa"></div>
              <a href="https://ambientemisto.mav.com.br/">
                <li className="ambiente_misto lgpd_nav">Microsoft <img src={inte} alt="integração"/> MAV Mail</li>
              </a>
              <a href="https://oportunidade.mav.com.br/">
                <li className="ambiente_misto lgpd_nav">Google <img src={inte} alt="integração"/> MAV Mail</li>
              </a>
            </ul>
          </div>

          <div className="menu_drag">
            <div className="hamburguer-bt">
              <div className="hamburguer-bt__stripe hamburguer-bt__stripe__top"></div>
              <div className="hamburguer-bt__stripe hamburguer-bt__stripe__middle"></div>
              <div className="hamburguer-bt__stripe hamburguer-bt__stripe__bottom"></div>
            </div>
          </div>
        </div>
        <div className="menu_full">
          <div className="nav_wrapper_menu_full">
            <ul>
              <Link to={"/"}>
                <li>Inicio</li>
              </Link>
              <Link to={"/mail"}>
                <li>E-mail Profissional</li>
              </Link>
              <Link to={"/antispam"}>
                <li>AntiSpam</li>
              </Link>
              <li>SMTP</li>
              <li>Vps</li>

              <a href="https://ambientemisto.mav.com.br/">
                <li className="ambiente_misto">Microsoft e MAV Mail</li>
              </a>
              <a href="https://oportunidade.mav.com.br/">
                <li className="ambiente_misto">Google e MAV Mail</li>
              </a>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export const faqList = {


  // title: "Perguntas frequentes",
  rows: [
  {
    id: 1,
    title: "Vocês realizam a migração dos e-mails e site?",
    content: "Sim, todo o processo de migração dos serviços de e-mail e site será realizado de forma gratuita pela equipe de suporte da MAV Tecnologia.",
  },
  {
    id: 2,
    title: "Por quanto tempo meus e-mails serão arquivados?",
    content: "A MAV Tecnologia possui planos que contemplam com períodos de arquivamento diferentes, sendo o menor tempo de arquivamento por 7 dias e o período máximo por 10 anos.",
  },
  {
    id: 3,
    title: "Posso mudar as regras de bloqueio do Antispam?",
    content: "Sim, o Antispam possui 5 níveis de configuração: Desabilitado, Baixo, Médio, Alto e Muito alto. Você poderá gerenciar o Antispam de forma simples e conseguirá atribuir regras diferentes para cada usuário e/ou grupos. ",
  },
  {
    id: 4,
    title: "Quando uma mensagem é bloqueada como SPAM, será possível ter acesso a ela?",
    content: "Sim, a partir do Painel de Controle será possível criar regras de alerta sobre bloqueios de SPAM.",
  },
  {
    id: 5,
    title: "É possível configurar uma lista branca e lista negra de domínios, IPs e remetentes?",
    content: "Sim, a partir do Painel de Controle da MAV Tecnologia, você poderá determinar remetentes que são confiáveis ou não para o seu domínio.",
  },
  {
    id: 6,
    title: "É possível limitar o horário de acesso aos e-mails?",
    content: "Sim, se na sua empresa for permitido acessar e-mail somente em horário comercial, por exemplo, é possível criar uma regra para que fora deste horário não seja possível enviar/receber e-mails.",
  },
  {
    id: 7,
    title: "Se uma mensagem for excluída, é possível recuperar?",
    content: "Sim, a MAV Tecnologia irá arquivar os e-mails de um domínio pelo tempo determinado no plano contratado, sendo o período mínimo de 7 dias e o período máximo de 10 anos. Dentro desse prazo, será possível recuperar uma mensagem trafegada pelo servidores de e-mail da MAV Tecnologia.",
  },
  {
    id: 8,
    title: "Como posso saber se um e-mail foi recebido/ entregue com sucesso?",
    content: "A partir do Painel de Controle da MAV Tecnologia, você terá acesso a todo o tráfego das mensagens e poderá verificar o status de cada uma delas, assim, poderá saber se uma mensagem foi recebida/entregue com sucesso.",
  },


  {
    id: 9,
    title: "Quais são as formas de acesso aos e-mails?",
    content: "Você terá acesso aos e-mails utilizando clientes de e-mail (Outlook, Thunderbird, Mac Mail e outros), também poderá configurar sua conta em smartphones e caso prefira, poderá utilizar o webmail pelo endereço webmail.seudomínio.com.br",
  },
  {
    id: 10,
    title: "Suportam hospedagem de site em Wordpress?",
    content: "Sim, no Painel Plesk da MAV Tecnologia você poderá instalar o Wordpress com poucos cliques.",
  },
  {
    id: 11,
    title: "Possuem rotina de backup de sites?",
    content: "A partir do Painel Plesk, você poderá configurar uma rotina de backup do site da sua empresa.",
  },
  {
    id: 12,
    title: "Meu site foi invadido, o que devo fazer?",
    content: "Para ajudar na segurança do seu site, a MAV Tecnologia disponibiliza um plugin de segurança de site que identifica arquivos maliciosos e faz a varredura de todo o conteúdo com apenas 1 clique. O plugin é um recurso premium que deverá ser contratado para sua hospedagem web. Além disso, é recomendado que sempre faça um backup do seu site para que em casos de comprometimento do mesmo seja possível restaurar-lo.",
  },
]
};

import React, { Component } from 'react';

// import { Container } from './styles';

export default class BannerMail extends Component {
  render() {
    return (
    <div className="banner_antispam">
        

    </div>
    );
  }
}

import React, { Component } from "react";

// import { Container } from './styles';

export default class Call extends Component {
  render() {
    return (
      <div className="call_wrapper">
        {" "}
        <div className="text_call">
          Precisa de ajuda? Ligue para
          <strong>&nbsp; (31) 3211-7777 &nbsp;</strong> para falar com o nosso
          comercial ou
          <a href="http://mav.com.br/agenda"> &nbsp;clique aqui&nbsp; </a> e
          agende uma apresentação completa!
        </div>
      </div>
    );
  }
}

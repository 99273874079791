import React from 'react';
function Error() {
  return <div className='error_wrapper'>

    <div className='error'>
        <h1>404 | página não encontrada</h1>
    </div>
  </div>;
}

export default Error;
import React, { useEffect } from "react";
import BannerMail from "./BannerMail";
// import Comparative from "./Comparative";
// import Func from "./Func";
// import Plans from "./Plans";
import antispam from "../../assets/img/png/antispam4.png";
import auditoria from "../../assets/img/png/imagem_para_auditoria.png";
import controle from "../../assets/img/png/recibo_grid.png";

import recibo from "../../assets/img/png/recibo_de_entrega.png";

import backup from "../../assets/img/png/backup.png";
import Formulario from "./Formulario";
import "./style.css";
import Table from "./Table";
import Faq from "./Faq";
import Clients from "./Clients";
import HostWeb from "./HostWeb";
function Mailc() {
  useEffect(() => {
    document.title = "MAV Tecnologia - Serviços de E-mail";
  }, []);
  return (
    <div className="mailb_wrapper">
      <BannerMail />
      <HostWeb />

      <div className="funcinalidades_container mmb">
        <div className="funcionalidades_wrapper">
          <div className="products_wrapper_description mbprod" id="antispam">
            <br />
            <br />
            <br />
            <br />
            <h1 className="title_products">MAV Antispam</h1>
            <div className="line"></div>
            <div className="description_products">
              Garanta 99,9% de eficácia na proteção contra spams, fraudes e
              phishing (roubo de informações) que chegam por e-mail diariamente.
              Para isso, são feitas análises de textos do e-mail, black list e
              assinaturas geradas automaticamente. O bloqueio desses spams além
              de garantir a segurança também aumenta a produtividade, evitando
              perda de tempo com e-mails que não são o foco do seu negócio.
            </div>
          </div>

          <img className="imagem_products rec" src={antispam} alt="mail" />
          <button className="saiba_mais">Saiba mais</button>
          <br />
          <br />
          <br />
        </div>
      </div>

      <div className="auditoria_wrapper" id="auditoria">
        <div className="products_wrapper_description mbprod">
          <h1 className="title_products">Arquivamento</h1>
          <div className="line"></div>
          <div className="description_products mb">
            Acesse o registro de todo o tráfego e conteúdo dos seus e-mails.
            Além do melhor monitoramento dos dados, é possível recuperar
            mensagens já excluídas pelos usuários e fazer o arquivamento, ou
            seja, a documentação dessas informações.
          </div>
        </div>
        <img
          className="imagem_products rec print_auditoria"
          src={auditoria}
          alt="auditoria"
        />

        <button className="saiba_mais">Saiba mais</button>
      </div>

      <div className="controle_wrapper" id="controle">
        <div className="products_wrapper_description mbprod">
          <h1 className="title_products">Controle de Acesso</h1>
          <div className="line"></div>
          <div className="description_products">
            Evite acessos inesperados que podem gerar horas extras de seus
            colaboradores ou aumentar o risco de vazamentos de informações
            confidenciais. Basta bloquear o acesso aos e-mails fora do horário
            comercial ou da rede da empresa.
          </div>
        </div>

        <img
          className="imagem_products print_controle rec controle_img_rec"
          src={controle}
          width="80%"
          alt="controle"
        />

        <button className="saiba_mais">Saiba mais</button>
      </div>

      <div className="backup_wrapper" id="backup">
        <div className="products_wrapper_description mbprod">
          <h1 className="title_products">Backup</h1>
          <div className="line"></div>
          <div className="description_products">
            Tenha cópias de segurança de todos os e-mails que circulam na
            empresa e recupere qualquer mensagem, de maneira imediata. Esses
            e-mails não podem ser alterados ou apagados e ficam armazenados até
            10 anos.
          </div>
        </div>

        <img
          className="imagem_products rec print_backup"
          src={backup}
          alt="backup"
        />

        <button className="saiba_mais">Saiba mais</button>
      </div>

      <div className="recibo_wrapper" id="recibo">
        <div className="products_wrapper_description mbprod">
          <h1 className="title_products">Recibo de Entrega</h1>
          <div className="line"></div>
          <div className="description_products">
            Identifique problemas no envio de e-mails e evite falhas na
            comunicação com seu cliente. Disponibilizamos relatórios para que
            você cheque se um e-mail saiu da sua caixa de saída ou parou no
            servidor do destinatário.
          </div>
        </div>

        <img
          className="imagem_products rec rec_grid"
          src={recibo}
          width="80%"
          alt="recibo"
        />

        <button className="saiba_mais">Saiba mais</button>
        <br />
        <br />
        <br />
      </div>
      <Clients />
      <Table />

      <div className="button_cta">Clique Aqui e saiba mais</div>

      <Formulario />
      <Faq />
    </div>
  );
}
export default Mailc;

import React, { Component } from "react";
import Slider from "react-slick";
import smtp_img_6 from "../../assets/img/6.png";
import smtp_img_4 from "../../assets/img/4.png";
import smtp_img_5 from "../../assets/img/5.png";
import smtp_img_8 from "../../assets/img/8.png";
import smtp_img_9 from "../../assets/img/9.png";
import $ from "jquery";
export default class Smtp extends Component {
  componentDidMount() {
    $(window).scrollTop(0);
    return false;
  }
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className="smtp_wrapper">
        <div className="banner_smtp">
          <Slider {...settings}>
            <div className="banner_image_a"></div>
          </Slider>
        </div>

        <div className="zig_wrapper">
          <div className="info_zig_content">
            <h1>
              Especialistas em Serviços de <br />
              e-mail
            </h1>
            <p>
              {" "}
              Desenvolvemos um serviço SMTP poderoso para que sua empresa possa
              enviar com facilidade todos os seus e-mails importantes, como
              notas fiscais, boletos, notificações de sofwares ERP e outros.
            </p>
          </div>
          <div className="img_zig">
            <img src={smtp_img_8} width="50%" alt="smtp" />
          </div>
        </div>

        <div className="zig_wrapper reverse">
          <div className="info_zig_content">
            <h1>Configuração Simples</h1>
            <p>
              Configure facilmente os envios utilizando o MAV SMTP, em poucos
              minutos o seu serviço estará ativado e funcionando. <br />
              <br />
              Caso necessite de auxílio, nossa equipe de Suporte Técnico está
              preparada para te ajudar.
            </p>
          </div>
          <div className="img_zig">
            <img src={smtp_img_6} width="55%" alt="smtp" />
          </div>
        </div>

        <div className="zig_wrapper">
          <div className="info_zig_content">
            <h1>Acompanhe seus resultados</h1>
            <p>
              O MAV SMTP possui um Painel de Controle dedicado para que você
              possa analisar todo o tráfego de e-mails enviados da sua empresa.
              <br />
              <br />
              Com poucos cliques você terá acesso a relatórios que serão úteis
              no seu dia a dia.
            </p>
          </div>
          <div className="img_zig">
            <img src={smtp_img_4} width="60%" alt="smtp" />
          </div>
        </div>

        <div className="zig_wrapper reverse">
          <div className="info_zig_content">
            <h1>Sem limite de envio diário</h1>
            <p>
              Não limitaremos o seu envio diário, então, se sua empresa
              contratar um pacote de 10 mil envios/mês, vocẽs poderão utilizá-lo
              a qualquer momento, até que o limite do seu pacote seja atingido.
            </p>
          </div>
          <div className="img_zig">
            <img src={smtp_img_5} width="60%" alt="smtp" />
          </div>
        </div>
        <div className="zig_wrapper">
          <div className="info_zig_content">
            <h1>Planos de serviço flexíveis</h1>
            <p>
              Escolha um plano que melhor se adequa as necessidades da sua
              empresa, seja ela uma empresa que necessita de um número baixo de
              envios ou de um elevado número de envios diarário, semanal ou
              mensal.
            </p>
          </div>
          <div className="img_zig">
            <img src={smtp_img_9} width="60%" alt="smtp" />
          </div>
        </div>
      </div>
    );
  }
}

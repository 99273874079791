import React, { Component } from "react";

import recibo from "../../../../assets/img/png/recibo_de_entrega.png";

export default class Recibo extends Component {
  render() {
    return (
      <div className="recibo_wrapper" id="recibo">
        <div className="products_wrapper_description">
          <h1 className="title_products">Recibo de Entrega</h1>
          <div className="line"></div>
          <div className="description_products">
            Identifique problemas no envio de e-mails e evite falhas na
            comunicação com seu cliente. Disponibilizamos relatórios para que
            você cheque se um e-mail saiu da sua caixa de saída ou parou no
            servidor do destinatário.
          </div>
        </div>

        <img
          className="imagem_products rec rec_grid"
          src={recibo}
          width="80%"
          alt="recibo"
        />
      </div>
    );
  }
}

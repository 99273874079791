import React, { Component } from 'react';

// import { Container } from './styles';

export default class MavTop extends Component {
  render() {
    return (
    <div className="MavTop_wrapper">
            <div className="title_wrapper">
                {/* <div className='subtitle'><span/> Over 1,000 Companies Love Hostiko <span/></div> */}
                <h1>MAV Tecnologia</h1>
                <div className="line"></div>
            </div>

            <div className="boxes_mav_wrapper">
                <div className="boxes_mav_top">
                    <div className="box_mav">
                        <h3>Pesquisa e desenvolvimento</h3>
                        <p>Somos uma empresa genuinamente brasileira, que dedica
                        20 mil horas por ano na constante melhoria de nossos
                        produtos e no desenvolvimento de novas soluções.</p>

                    </div>
                    <div className="box_mav">
                    <h3>Escalabilidade dos produtos</h3>
                        <p>Ferramentas múltiplas e completas 
                            compõem nosso portfólio de produtos e
                             atendem integralmente nossos clientes à
                              medida em que novas necessidades vão
                               surgindo.</p>
                    </div>
                </div>
                <div className="boxes_mav_bottom">
                    <div className="box_mav">
                    <h3>Melhor relação custo x benefício</h3>
                        <p>Estamos na vanguarda da inovação. 
                            E como desenvolvemos nossos próprios
                             produtos, oferecemos as melhores soluções 
                             com os preços mais competitivos do mercado.</p>
                    </div>
                    <div className="box_mav">
                    <h3>Atendimento técnico personalizado</h3>
                        <p>Nossa equipe interna e nossos canais 
                            de comunicação (telefone, e-mail, 
                            WhatsApp, Skype e mídias sociais) estão 
                            à disposição dos clientes, para auxiliá-los
                             em todas as suas demandas.</p>
                    </div>
                </div>

            </div>
    </div>
    );
  }
}

import React, { Component } from "react";

import controle from "../../../../assets/img/png/recibo_grid.png";

export default class Controle extends Component {
  render() {
    return (
      <div className="controle_wrapper" id="controle">
        <div className="products_wrapper_description">
          <h1 className="title_products">Controle de Acesso</h1>
          <div className="line"></div>
          <div className="description_products">
            Evite acessos inesperados que podem gerar horas extras de seus
            colaboradores ou aumentar o risco de vazamentos de informações
            confidenciais. Basta bloquear o acesso aos e-mails fora do horário
            comercial ou da rede da empresa.
          </div>
        </div>

        <img
          className="imagem_products print_controle rec controle_img_rec"
          src={controle}
          width="80%"
          alt="controle"
        />
      </div>
    );
  }
}

import React, { Component } from "react";

import backup from "../../../../assets/img/png/backup.png";

export default class Backup extends Component {
  render() {
    return (
      <div className="backup_wrapper" id="backup">
        <div className="products_wrapper_description">
          <h1 className="title_products">Backup</h1>
          <div className="line"></div>
          <div className="description_products">
            Tenha cópias de segurança de todos os e-mails que circulam na
            empresa e recupere qualquer mensagem, de maneira imediata. Esses
            e-mails não podem ser alterados ou apagados e ficam armazenados até
            10 anos.
          </div>
        </div>

        <img
          className="imagem_products rec print_backup"
          src={backup}
          alt="backup"
        />
      </div>
    );
  }
}

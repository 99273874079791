import React, { Component } from 'react';
import auditoria from '../../../../assets/img/png/imagem_para_auditoria.png'

export default class Auditoria extends Component {
  render() {
    return (
    <div className="auditoria_wrapper" id="auditoria">
            <div className="products_wrapper_description">
            <h1 className="title_products">Arquivamento</h1>
            <div className="line"></div>
            <div className="description_products">
              Acesse o registro de todo o tráfego e
              conteúdo dos seus e-mails. Além do melhor
              monitoramento dos dados, é possível
              recuperar mensagens já excluídas pelos
              usuários e fazer o arquivamento, ou seja,
              a documentação dessas informações.  
          </div>
        </div>
        <img className="imagem_products rec print_auditoria" src={auditoria} alt="auditoria"/>
    </div>
    );
  }
}

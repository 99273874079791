import React,{useState}  from "react";
import "./style.css";
import info from "../../../assets/img/info.svg";
import axios from "axios";
import qs from "qs";
// import info from "../../../assets/img/info.svg";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import InputMask from "react-input-mask";
import { Form } from "react-bootstrap";
// import 'bootstrap/dist/css/bootstrap.min.css';
function Formulario() {

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [telefone, setTelefone] = useState("");
  const [erro, setErro] = useState();
  const [success, setSuccess] = useState();
  const [open, setOpen] = useState(false);

  const [display, setDisplay] = useState("none");
  const [displayenvi, setDisplayEnvi] = useState("block");

  function cadastrar() {
    if (!nome || !email || !empresa || !telefone) {
      setErro("Preencha todos os campos!");
      setTimeout(() => {
        setErro("");
      }, 3000);
    } else {
      setDisplay("block");
      setDisplayEnvi("none");

      var data = qs.stringify({
        token:
          "JDJhJDA4JENmMWYxMWVQQXJLbEJKb21NMEY2YS5LMHBKSkNteG9vOWl0ZkNGM0hMTjdaWVE4TVVpZE4u",
        nome: nome,
        email: email,
        sua_empresa: empresa,
        telefone: telefone
      });
      var config = {
        method: "post",
        url: " https://checkout.mav.com.br/api/leads/send-services-test-form",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          console.log(response);
          if (response.status === 200) {
            setTimeout(() => {
              setOpen(true);
            }, 500);

            setDisplay("none");
            setDisplayEnvi("block");

            setSuccess("Usuario cadastrado com sucesso");
            setTimeout(() => {
              setSuccess("");

              setNome("");
              setEmail("");
              setEmpresa("");
              setTelefone("");
            }, 3000);
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error.status === 400) {
            setErro("Usuario já cadastrado");
          }
        });
    }
  }

  return (
    <div className="form_wrapper">
            <Popup open={open} modal nested>
        {(close) => (
          <div className="modal">
            <div className="success">Dados enviados com sucesso</div>
            <div className="content">
              Obrigado, seus dados foram enviados com sucesso, faremos contato
              para ativar o teste!
            </div>
          </div>
        )}
      </Popup>
      <div className="form_container">
        <div className="left">
          <div className="headline">
            Insira seus dados no formulário ao lado e receba um período de teste
            grátis.
          </div>
          <img src={info} alt="info"></img>
        </div>
        <div className="right">

          <Form>
            <div className="form">
            <div className="title_default">QUERO INICIAR MEU TESTE GRÁTIS</div>
            <div className="subtitle_default">Insira seus dados abaixo para realizarmos contato.</div>
              <div>
                <div className="title_form">Nome</div>
                <Form.Control 
                  type="text"
                  value={nome}
                  onChange={(a) => setNome(a.target.value)}
                  placeholder="digite seu nome"
                />
              </div>

              <div>
                <div className="title_form">email</div>
                <Form.Control
                type="email"
                  value={email}
                  placeholder="digite seu e-mail"
                  onChange={(a) => setEmail(a.target.value)}
                />
              </div>

              <div>
                <div className="title_form">empresa</div>
                <Form.Control 
                  type="text"
                  value={empresa}
                  onChange={(a) => setEmpresa(a.target.value)}
                  placeholder="digite sua empresa"
                />
              </div>

              <div>
                <div className="title_form">telefone</div>
                <InputMask
                  mask="(99) 99999-9999"
                  type="text"
                  value={telefone}
                  onChange={(a) => setTelefone(a.target.value)}
                  placeholder="digite seu telefone"
                />
              </div>
              <div>
                <div className="button" onClick={() => cadastrar()}>
                  <div style={{ display: displayenvi }} className="enviar">
                    Enviar
                  </div>

                  <div
                    style={{ display: display }}
                    className="loader loader--style1"
                    title="0"
                  >
                    <svg
                      version="1.1"
                      id="loader-1"
                      x="0px"
                      y="0px"
                      width="40px"
                      height="40px"
                      viewBox="0 0 40 40"
                      enableBackground="new 0 0 40 40"
                    >
                      <path
                        opacity="0.2"
                        fill="#ddd"
                        d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                      />
                      <path
                        fill="#fff"
                        d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
    C22.32,8.481,24.301,9.057,26.013,10.047z"
                      >
                        <animateTransform
                          attributeType="xml"
                          attributeName="transform"
                          type="rotate"
                          from="0 20 20"
                          to="360 20 20"
                          dur="0.5s"
                          repeatCount="indefinite"
                        />
                      </path>
                    </svg>
                  </div>
                </div>

                <div className="erro_message">{erro}</div>
                <div className="success_message">{success}</div>
              </div>
            </div>
          </Form>




          <Popup
            trigger={<button className="whatsButton"></button>}
            modal
            nested
          >
            {(close) => (
              <div className="modal">
                <button className="close" onClick={close}>
                  &times;
                </button>

                <form
                  method="POST"
                  action="https://mav747.activehosted.com/proc.php"
                  id="_form_25_"
                  className="_form _form_25 _inline-form  _dark"
                  novalidate
                >
                  <input type="hidden" name="u" value="25" />
                  <input type="hidden" name="f" value="25" />
                  <input type="hidden" name="s" />
                  <input type="hidden" name="c" value="0" />
                  <input type="hidden" name="m" value="0" />
                  <input type="hidden" name="act" value="sub" />
                  <input type="hidden" name="v" value="2" />
                  <input
                    type="hidden"
                    name="or"
                    value="99cded72339f56361262d53d49df9529"
                  />
                  <div className="_form-content">
                    <div className="_form_element _x57371426 _full_width _clear">
                      <div className="_form-title">
                        Preencha os campos abaixo para conversar com a nossa
                        equipe no WhatsApp
                      </div>
                    </div>
                    <div className="_form_element _x60068928 _full_width ">
                      <label for="fullname" className="_form-label">
                        Nome*
                      </label>
                      <div className="_field-wrapper">
                        <input
                          type="text"
                          id="fullname"
                          name="fullname"
                          placeholder="Digite seu nome"
                          required
                        />
                      </div>
                    </div>
                    <div className="_form_element _x15249745 _full_width ">
                      <label for="email" className="_form-label">
                        E-mail*
                      </label>
                      <div className="_field-wrapper">
                        <input
                          type="text"
                          id="email"
                          name="email"
                          placeholder="Digite seu e-mail"
                          required
                        />
                      </div>
                    </div>
                    <div className="_form_element _field2 _full_width ">
                      <label for="field[2]" className="_form-label">
                        Telefone*
                      </label>
                      <div className="_field-wrapper">
                        <input
                          type="text"
                          id="field[2]"
                          name="field[2]"
                          // value=""
                          placeholder="Digite seu telefone"
                          required
                        />
                      </div>
                    </div>
                    <div className="_button-wrapper _full_width">
                      <button
                        id="_form_25_submit"
                        className="_submit"
                        type="submit"
                      >
                        Iniciar conversa
                      </button>
                    </div>
                    <div className="_clear-element"></div>
                  </div>
                  <div
                    className="_form-thank-you"
                    style={{ display: "none" }}
                  ></div>
                </form>
              </div>
            )}
          </Popup>
        </div>
      </div>
    </div>
  );
}

export default Formulario;

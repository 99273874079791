import React, { Component } from 'react';
import antispam from "../../../assets/img/antispam5.png";
import infografico from "../../../assets/img/infografico1.png";
import selo from "../../../assets/img/satisfacao.png";
import $ from 'jquery'
export default class Funcionalidades extends Component {
  componentDidMount(){
    var $doc = $('html, body');

        $('a').click(function() {
        $doc.animate({
        scrollTop: $( $.attr(this, 'href') ).offset().top
        }, 1000);
        return false;
        });
  }
  render() {
    return (
    <div className="funcinalidades_container">

    <div className='funcionalidades_wrapper'>
        <div className="title_funcionalidades_wrapper">

        <div className="products_wrapper_description" >
            <h1 className="title_products">MAV Antispam</h1>
            <div className="line"></div>
            <div className="description_products">
              Garanta 99,9% de eficácia na proteção
              contra spams, fraudes e phishing
              (roubo de informações), que chegam por e-mail
              diariamente. Para isso, são feitas análises
              de textos do e-mail, black list e assinaturas
              geradas automaticamente. O bloqueio desses
              spams além de garantir a segurança também
              aumenta a produtividade, evitando perda de
              tempo com e-mails que não são o foco do
              seu negócio.
          </div>
        </div>

        </div>

        <div className="bottom_antispam_wrapper" id="antispam">
          <div className="text_antispam_bootom">
            Realizaremos a <strong>filtragem dos seus e-mails </strong>
            e entregaremos as mensagens ao seu servidor local.
            <br></br>Deixe que <strong>nós cuidaremos dos SPAMs para você!</strong>
          </div>
        </div>


        <img className="imagem_products rec" src={antispam} alt="antispam"/>

        <div className="description"></div>


    </div>
<div className="infografico_wrapper">

<h1 className="title_products t_a">Funcionamento do MAV Antispam</h1>
<div className="line"></div>


<img className="imagem_products rec" src={infografico} alt="antispam"/>


</div>

<div className="antispam_more">


<div className="box_antispam_teste">
<div className="description_products_infografico">
  <h2>Garantia de Satisfação</h2>

  Para que a sua empresa possa desfrutar dos recursos do MAV Antispam, oferecemos <strong>15 dias para você testar</strong> os serviços de forma <strong>totalmente gratuita!</strong>

<br/>
<br/>
Caso não queira continuar com o serviço MAV Antispam após os 15 dias de testes, não cobramos nenhuma taxa!
      
      <div className='btn_teste_gratis'>Quero Testar Agora</div>
      </div>

      <div className="img_area_teste">
        <img src={selo} alt="antispam"/>
      </div>
</div>
</div>
</div>
    );
  }
}

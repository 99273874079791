import React, { Component } from "react";

// import { Container } from './styles';

export default class LGPD extends Component {
  render() {
    return (
      <div className="lgdp_wrapper">
        <div className="banner_lgpd"></div>

        <div className="content_lgpd">
          <h1>Proteção de dados na internet: quais soluções podem ajudar?</h1>
          Sua empresa se preocupa com a proteção de dados na internet? Agora,
          mais do que nunca, é importante pensar nisso. Em 2020,{" "}
          <strong>entrará em vigor a nova legislação</strong> que, além de mudar
          a relação entre empresas e dados coletados no meio digital, prevê
          algumas punições. Quem deixar de cumprir as devidas regulamentações
          pode receber multa de até 4% sobre o faturamento da empresa. Por isso,
          chegou a hora de entender exatamente como funciona a proteção de dados
          na internet e as soluções essenciais para manter o sigilo dessas
          informações. Como será a proteção de dados com a nova regulamentação?
          Até 14 de agosto de 2018, o Brasil carecia de regulamentação
          específica para a <strong>proteção de dados na internet</strong>.
          Nesta data, foi homologada a Lei Geral de Proteção de Dados pessoais
          (LGPD), que mudou significativamente a relação de empresas com os
          dados coletados de forma online.
          <h1>O que é a LGPD?</h1>A legislação foi criada para regulamentar a
          ação de empresas na internet, mais especificamente na coleta e uso de
          dados dos usuários. Ela exige que as empresas adotem uma nova postura
          em relação a dados de clientes e buscar o consentimento do público.
          Atualmente, empresas de variados segmentos coletam uma quantidade
          significativa de dados na internet. No entanto, essas informações não
          são usadas e distribuídas com o devido controle, colocando todos os
          envolvidos em risco. A intenção é mudar esse cenário e estimular a
          adoção de tecnologias para garantir segurança no acesso e
          transferência de informações. É{" "}
          <strong>importante enxergar a LGPD não como um risco</strong>, mas uma
          oportunidade para empresas desenvolverem processos e ferramentas para
          proteção de dados na internet.
          <h1>A quem se aplica a LGPD?</h1>
          Todas as empresas precisarão aplicar os regulamentos da LGPD a partir
          de 2020. Isso inclui negócios públicos e privados de qualquer setor,
          impactando fornecedores, produtos e terceirizados. A lei determina que
          qualquer um, independentemente do porte da empresa, adote medidas de
          segurança proativas, ou seja, a empresa não espera que aconteça um
          problema para alterar sua política de proteção de dados e faz
          modificações para estar sempre à frente das ameaças e manter seus
          usuários e colaboradores seguros. Assim como no texto da lei europeia,
          a LGPD se aplica a negócios do exterior que atuam no território
          brasileiro. Isso significa que Google, Amazon, Facebook e outros
          grandes nomes também deverão atualizar suas medidas de proteção.
          <h1>O que a lei determina?</h1>
          De acordo com o texto da LGPD, os usuários terão maior poder sobre
          seus dados na internet. Uma das novidades é a possibilidade de pedir
          para ver o que empresas guardam de informações e pedir para alterar ou
          excluí-las. Além disso,{" "}
          <strong>
            negócios só poderão coletar dados online com a autorização do
            usuário
          </strong>
          . No caso de menores de idade, será necessário ter a autorização de um
          responsável. É importante saber que não é o suficiente conseguir um
          clique em um documento de termos de uso generalizado. A pessoa precisa
          saber qual é o fim exato de seus dados, como uso em campanhas de
          marketing ou em análises de big data. Caso o usuário encerre o
          relacionamento com a empresa, ela será obrigada a excluir suas
          informações e não usá-las em qualquer objetivo futuro. Como ocorre o
          processo da LGPD
          <h1>O que é General Data Protection Regulation (GDPR)?</h1>
          Se você acompanhou o processo de criação e aprovação da nova lei para
          proteção de dados na internet no Brasil, deve ter ouvido falar da
          GDPR. A General Data Protection Regulation é uma legislação da União
          Europeia, que inspirou boa parte da lei adotada no Brasil. A
          regulamentação fez qualquer empresa atuante na Europa adotar normas
          mais rígidas para captação e uso de dados. Isso inclui gigantes
          tecnológicas, como Google, Amazon e Adobe, que são estrangeiras, mas
          trabalham no continente. As normas também instruíram empresas que
          trabalham com dados a deixar claro em suas políticas de segurança
          sobre a adesão à GDPR. A lei já se tornou um modelo que inspirou
          regras em todo o mundo.
          <h1>Como aplicar a proteção de dados da internet na empresa</h1>O
          prazo para realizar mudanças que garantem a proteção de dados no seu
          negócio está acabando. Mas o que será preciso fazer exatamente?
          Existem diversas maneiras de melhorar a segurança para usuários e
          empresas, como você verá nas soluções que apresentamos abaixo.
          <h1>Backup de dados</h1>O backup é importantíssimo para empresas que
          desejam manter um alto padrão de proteção de dados na internet. Uma
          das soluções mais populares, atualmente, é o uso da nuvem para
          armazenar e fazer backup de informações. Uma grande vantagem desse
          tipo de serviço é a possibilidade de otimizar recursos a um custo
          acessível. No entanto, é importante escolher com cuidado o fornecedor
          de serviços em nuvem. A facilidade de acesso a esse tipo de provedor
          pode gerar sérios riscos à segurança da empresa. Antes de escolher o{" "}
          <strong>provedor de serviços de backup na nuvem</strong>, é preciso
          ter certeza que ele também trabalha de acordo com as novas
          regulamentações da LGPD. Além de estar preparado para cumprir as
          exigências da lei, também devem existir acordos entre o fornecedor e o
          contratante sobre o controle de dados. O ideal é que dados
          confidenciais e pessoais de usuários não permaneçam armazenados por
          mais tempo do que necessário. O contratante deve estabelecer períodos
          de retenção e, quando terminarem, realizar a exclusão. É a melhor
          forma de garantir a proteção de dados no backup na nuvem.
          <h1>Certificados SSL</h1>
          Por último,{" "}
          <strong>
            é importante utilizar certificados SSL em qualquer site empresarial
          </strong>
          . Mesmo antes da LGPD eles já faziam a diferença para usuários,
          especialmente quando navegadores começaram a indicar sites sem o
          certificado como inseguros. O certificado é utilizado para
          criptografar informações transmitidas através do site, evitando o
          roubo ou compartilhamento indevido das mesmas. Ele pode ser contratado
          junto da maior parte dos serviços de hospedagem. Para saber se um site
          já possui o certificado, basta observar o início da URL. Sites que
          possuem o SSL são identificadas com https, enquanto sites inseguros
          são identificados com http. No entanto, de nada adianta ter soluções
          de criptografia em um site se as informações coletadas não têm um
          destino seguro. Por isso, o recomendado é utilizar o SSL em conjunto
          com um servidor dedicado e as melhores soluções de segurança.
          <h1>Servidor dedicado</h1>O servidor dedicado é um grande aliado no
          mundo empresarial. Seus recursos são exclusivos da empresa,{" "}
          <strong>incluindo memória e capacidade de processamento</strong>, o
          que torna uma grande vantagem utilizá-lo. Quem contrata um servidor
          dedicado consegue ter controle total sobre seus recursos, além de
          poder customizar o serviço. Em relação à proteção de dados na
          internet, contratar um servidor dedicado significa que toda a
          segurança projetada (como firewall e scripts) são de uso exclusivo, o
          que diminui as chances de brechas de segurança por conta de aplicações
          de terceiros em ambientes compartilhados. Mas é importante avaliar a
          real necessidade de adquirir um servidor dedicado, isso porque os
          custos são elevados e, em muitos casos, uma hospedagem compartilhada
          já oferece os recursos necessários.
          <h1>Crie processos</h1>
          Além dessas tecnologias que colaboram com a segurança de forma
          prática, procure também criar processos, documentações e treinar uma
          equipe específica para tratar os dados que você recolhe dos seus
          usuários. Faça um mapeamento dos processos em relação ao tratamento
          das informações: como será a coleta, como e onde armazenar, quem pode
          alterar os dados, quem são os parceiros que manipulam as informações,
          plano de resposta a incidentes e etc.
        </div>
      </div>
    );
  }
}

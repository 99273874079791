import React from "react";
import FaqLi from "react-faq-component";
import "./style.css";
import { faqList } from "../../../Services/faq";
// import $ from "jquery";
function Faq() {
  // const [isSelected, setSelected] = useState("");

  // useEffect(() => {
  //   $(document).ready(function () {
  //     $(".faq_wrapper .faq_container .faq_box").click(function () {
  //       $(".faq_container .faq_box").removeClass("active");
  //       $(this).addClass("active");
  //     });
  //   });
  // });

  const styles = {
    bgColor: 'white',
    titleTextColor: "#000",
    rowTitleColor: "#000",
    rowTitleTextSize: 'medium',
    rowContentPaddingTop: '20px',
    rowContentPaddingBottom: '20px',
    arrowColor: "#f50",
  };

  const config = {
    animate: true,
    // arrowIcon: "V",
    tabFocus: true
  };

  return (
    <div className="faq_wrapper">
      <div className="faq_container">
        {/* {faqList.map((item, index) => {
          return (
            <div className="faq_box" onClick={()=>setSelected(item.id)}>
              <div className="title_faq">
                <i class="far fa-question-circle"></i> {item.title}
              </div>
              <div className="text_faq">{item.text}</div>
            </div>
          );
        })} */}

        <h1>Perguntas frequentes</h1>

        <FaqLi data={faqList} styles={styles} config={config} />
      </div>
    </div>
  );
}
export default Faq;

import React, { Component } from 'react';

// import { Container } from './styles';
import lgpd from '../../assets/docs/Infra+LGPD.pdf'
export default class Footer extends Component {
  render() {
    return(
    <div className="footer_wrapper">
      <div className="rights">Todos os direitos reservados @2019 – MAV Tecnologia</div>
      <div className="nav_footer">
      <ul>
        <a href="https://www.mav.com.br/suporte/wiki/wiki-mav-supramail-e-mail/politica-de-envio-em-massa/"><li>Política de SPAM</li></a>
          
        <a href={lgpd} target="blank"><li>LGPD</li></a>
          {/* <li>AntiSpam</li>
          <li>Vps</li> */}
        </ul>
      </div>

    </div>
    );
  }
}

import React, { Component } from "react";

import mavMail from "../../../assets/img/mavMail.png";
import mavAntispam from "../../../assets/img/mavAntispam.png";
import mavSmtp from "../../../assets/img/mavSmtp.png";
import mavVps from "../../../assets/img/vpslogo.svg";
import lgpd from "../../../assets/img/lgpd.svg";
import em from "../../../assets/img/em.svg";
import inte from "../../../assets/img/integrations.svg";
import { Link } from "react-router-dom";

export default class Products extends Component {
  render() {
    return (
      <div className="products_wrapper">
        <div className="title_wrapper">
          <h1>Nossas soluções</h1>
          <div className="subtitle">
            <span /> Estamos há mais de 18 anos no mercado garantido a qualidade
            no serviço de e-mails de grandes empresas <span />
          </div>
        </div>

        <div className="box_products_wrapper">
          <div className="box">
            <div className="icon_area">
              <div className="icon">
                <i className="fab fa-windows"></i>
              </div>
            </div>
            <div className="integration">
              MICROSOFT
              <br />
              <img src={inte} alt="mail" />
              <br /> MAV MAIL
            </div>
            <div className="btn_wrapper">
              <a
                href={"https://ambientemisto.mav.com.br"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="btn_access btn_microsoft">Acessar</div>
              </a>
            </div>
          </div>

          <div className="box">
            <div className="icon_area">
              <div className="icon">
                <i className="fab fa-google"></i>
              </div>
            </div>
            <div className="integration">
              GOOGLE
              <br />
              <img src={inte} alt="mail" />
              <br /> MAV MAIL
            </div>
            <div className="btn_wrapper">
              <a
                href={"https://oportunidade.mav.com.br"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="btn_access btn_google">Acessar</div>
              </a>
            </div>
          </div>

          <div className="box">
            <div className="icon_area">
              <div className="icon">
                <i className="far fa-envelope"></i>
              </div>
            </div>
            <div className="product_logo">
              <img src={mavMail} alt="mail" />
            </div>
            <div className="btn_wrapper">
              <Link to={"/mail"}>
                <div className="btn_access btn_mail">Acessar</div>
              </Link>
            </div>
          </div>

          <div className="box">
            <div className="icon_area">
              <div className="icon">
                <i className="far fa-envelope"></i>
              </div>
            </div>
            <div className="product_logo">
              <img src={mavAntispam} alt="mail" />
            </div>
            <div className="btn_wrapper">
              <Link to={"/antispam"}>
                <div className="btn_access btn_antispam">Acessar</div>
              </Link>
            </div>
          </div>

          <div className="box">
            <div className="icon_area">
              <div className="icon">
                <i className="far fa-envelope"></i>
              </div>
            </div>
            <div className="product_logo">
              <img src={mavSmtp} alt="mail" />
            </div>
            <div className="btn_wrapper">
              <Link to={"/smtp"}>
                <div className="btn_access btn_smtp">Acessar</div>
              </Link>
            </div>
          </div>

          <div className="box">
            <div className="icon_area">
              <div className="icon">
                <i className="far fa-envelope"></i>
              </div>
            </div>
            <div className="product_logo">
              <img src={mavVps} alt="mail" />
            </div>
            <div className="btn_wrapper">
              <Link to={"/vps"}>
                <div className="btn_access btn_vps">Acessar</div>
              </Link>
            </div>
          </div>

          <div className="box">
            <div className="icon_area">
              <div className="icon">
                <i className="far fa-envelope"></i>
              </div>
            </div>
            <div className="product_logo">
              <img src={lgpd} alt="mail" />
            </div>
            <div className="btn_wrapper">
              <a
                href={"https://lgpd.mav.com.br/"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="btn_access btn_lgpd">Acessar</div>
              </a>
            </div>
          </div>

          <div className="box">
            <div className="icon_area">
              <div className="icon">
                <i className="far fa-envelope"></i>
              </div>
            </div>
            <div className="product_logo">
              <img src={em} alt="mail" />
            </div>
            <div className="btn_wrapper">
              <a
                href={"https://em.mav.com.br/"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="btn_access btn_em">Acessar</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React from "react";

import "./style.css";

function HostWeb() {
  return (
    <div className="hostWeb_container">
      <div className="hostWeb_wrapper">
        <div className="tittle">Hospedagem web perfeita para sua empresa</div>

        <div className="boxes_hostweb">
          <div className="box_hostweb">
            <div className="title_box">
              Hospedagem 
              Compartilhada
            </div>
            Fácil, segura e com 5 contas de e-mail
            <div className="price_box">
            <i className="fas fa-sort-up"></i>Comece a partir de
              <div className="price">R$ 9,90/mês*</div>
            </div>
          </div>

          <div className="box_hostweb">
            <div className="title_box">
              Construtor de 
              Sites
            </div>
            Crie sites de forma simples sem nenhum conhecimento de HTML ou
            habilidades de design gráfico.
            <div className="info_hostweb">
            <i className="fas fa-sort-up"></i>Grátis em todos os planos de hospedagem
            </div>
          </div>

          <div className="box_hostweb">
            <div className="title_box">
              Hospedagem 
              WordPress
            </div>
            Crie sites profissionais de forma rápida ou migre o seu site
            Wordpress.
            <div className="info_hostweb">
            <i className="fas fa-sort-up"></i>Grátis em todos os planos de hospedagem
            </div>
          </div>

          <div className="box_hostweb">
            <div className="title_box">
              Hospedagem 
              VPS
            </div>
            Mais desempenho, controle e liberdade de upgrades 
            <div className="info_hostweb">
            <i className="fas fa-sort-up"></i>Consulte valores
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HostWeb;

import React from "react";

import "./style.css";

function Table() {
  return (
    <div className="table_wrapper">
      <div className="table_container">
        <div className="table_full">
          <div className="title_table"> Principais recursos dos serviços MAV Antispam, MAV Mail e MAV Colaborativo:</div>
          <div className="top_table">
            <div className="blackSpace"></div>
            <div className="categories_container">
              <div className="top_title">MAV Antispam</div>
              <div className="top_title"> MAV Mail</div>
              <div className="top_title"> MAV Colaborativo</div>
            </div>
          </div>

          {/* <div className="plans_container">
            <div className="leftAreaPlan"></div>
            <div className="botton_plans_wrapper">
              <div className="box_plans">
                <div className="title_plan">Plus</div>
                <div className="description">Para pessoas físicas</div>
                <div className="button_plan">comprar agora</div>
              </div>
              <div className="box_plans">
                <div className="title_plan">Family</div>
                <div className="description">Para pessoas físicas</div>
                <div className="button_plan">comprar agora</div>
              </div>
              <div className="box_plans">
                <div className="title_plan">Professional</div>
                <div className="description">Para pessoas físicas</div>
                <div className="button_plan">comprar agora</div>
              </div>
            </div>
          </div> */}

          {/* <div className="resources_wrapper">
            Principais recursos do Dropbox
          </div> */}



          <div className="plan_description_wrapper">

          <div className="description_plan_container">
              <div className="desc_title">Hospedagem das caixas postais na nuvem da MAV</div>
              <div className="option_container">
                <div className="desc_option">
                  <i className="fas fa-times"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
              </div>
            </div>
            <div className="description_plan_container">
              <div className="desc_title">Antispam Premium</div>
              <div className="option_container">
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
              </div>
            </div>

            <div className="description_plan_container">
              <div className="desc_title">
                Sincronização de agendas
              </div>
              <div className="option_container">
                <div className="desc_option">
                <i className="fas fa-times"></i>
                </div>
                <div className="desc_option">
                <i className="fas fa-times"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
              </div>
            </div>

            <div className="description_plan_container">
              <div className="desc_title">
                Sincronização de contatos
              </div>
              <div className="option_container">
                <div className="desc_option">
                <i className="fas fa-times"></i>
                </div>
                <div className="desc_option">
                <i className="fas fa-times"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
              </div>
            </div>

            <div className="description_plan_container">
              <div className="desc_title">
                Sincronização de e-mails em PC e smartphones
              </div>
              <div className="option_container">
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
              </div>
            </div>

            <div className="description_plan_container">
              <div className="desc_title">
                Controle de Acesso
              </div>
              <div className="option_container">
                <div className="desc_option">
                <i className="fas fa-times"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
              </div>
            </div>

            <div className="description_plan_container">
              <div className="desc_title">Recibo de entrega</div>
              <div className="option_container">
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
              </div>
            </div>

            <div className="description_plan_container">
              <div className="desc_title">Lista Branca</div>
              <div className="option_container">
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
              </div>
            </div>

            <div className="description_plan_container">
              <div className="desc_title">Lista Negra</div>
              <div className="option_container">
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
              </div>
            </div>

            <div className="description_plan_container">
              <div className="desc_title">
                Personalização do nível do Antispam
              </div>
              <div className="option_container">
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
              </div>
            </div>

            <div className="description_plan_container">
              <div className="desc_title">
                Aviso de recebimento de SPAM personalizável
              </div>
              <div className="option_container">
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
              </div>
            </div>

            <div className="description_plan_container">
              <div className="desc_title">
                Hospedagem de site
              </div>
              <div className="option_container">
                <div className="desc_option">
                <i className="fas fa-times"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
              </div>
            </div>

            <div className="description_plan_container">
              <div className="desc_title">Arquivamento dos e-mails</div>
              <div className="option_container">
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
              </div>
            </div>

            <div className="description_plan_container">
              <div className="desc_title">Arquivamento dos e-mails</div>
              <div className="option_container">
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
              </div>
            </div>

            <div className="description_plan_container">
              <div className="desc_title">Suporte Técnico</div>
              <div className="option_container">
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
              </div>
            </div>

            <div className="description_plan_container">
              <div className="desc_title">Migração Grátis</div>
              <div className="option_container">
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
                <div className="desc_option">
                  <i className="fas fa-check"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;

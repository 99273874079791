import React, { Component } from 'react';

import icon from "../../../../assets/img/checked.svg";

export default class Propriedades extends Component {
  render() {
    return (<div className="propriedades_wrapper">


<div className="title_wrapper">
    {/* <div className='subtitle'><span/> Over 1,000 Companies Love Hostiko <span/></div> */}
    <h1>MAV Antispam</h1> 
    <div className="line"></div>
    </div>

<div className="funcionalidades_icon_wrapper">


<div className="func">
  <img src={icon} width="20%" alt="icon"/>
  <div className="icon_funcinalidades scrollSuavel">Segurança</div>
</div>

<div className="func">
  <img src={icon} width="20%" alt="icon"/>
  <div className="icon_funcinalidades">Produtividade</div>
</div>

<div className="func">
  <img src={icon} width="20%" alt="icon"/>
<div className="icon_funcinalidades">Recursos</div>
</div>

<div className="func">
  <img src={icon} width="20%" alt="icon"/>
 <div className="icon_funcinalidades">Praticidade</div>
</div>

<div className="func">
  <img src={icon} width="20%" alt="icon"/>
  <div className="icon_funcinalidades">Desenvolvimento</div>
</div>

<div className="func">
  <img src={icon} width="20%" alt="icon"/>
  <div className="icon_funcinalidades">Acessibilidade</div>
</div>

<div className="func">
  <img src={icon} width="20%" alt="icon"/>
  <div className="icon_funcinalidades">confiabilidade</div>
</div>

<div className="func">
  <img src={icon} width="20%" alt="icon"/>
  <div className="icon_funcinalidades">possibilidades</div>
</div>
</div>
    </div>);
  }
}

import React from "react";

import "./style.css";
import bh from "../../../assets/clientes/bh.png";
import lider from "../../../assets/clientes/lider.png";
import linhares from "../../../assets/clientes/linhares.png";
import parvi from "../../../assets/clientes/parvi.jpg";
function Clients() {
  return (
    <div className="clients_wrapper">
      <h1 className="title_products">
        Conheça alguns dos nossos<br/>clientes pelo Brasil
      </h1>
      <div className="line"></div>

      <div className="logo_clients">
        <div className="box_clients">
          <img src={bh} alt="clients" />
        </div>
        <div className="box_clients">
          <img src={lider} alt="clients" />
        </div>
        <div className="box_clients">
          <img src={linhares} alt="clients" />
        </div>
        <div className="box_clients">
          <img src={parvi} alt="clients" />
        </div>
      </div>
    </div>
  );
}

export default Clients;

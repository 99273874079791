import React, { Component } from 'react';

import img from '../../assets/img/img.svg'

export default class Banner extends Component {
  render() {
    return( 
    <div className="banner_wrapper">
      <div className="text_banner_wrapper">
        <div className="text_container">
        <h1>Abaixo você conhecerá todas as nossas soluções para a sua empresa.</h1>
        <h4>Em caso de dúvidas, <a href="https://calendly.com/mav-tecnologia/backup-antispam"><strong>clique aqui</strong></a> e agende uma reunião com o nosso gerente comercial.</h4>
        </div>
      </div>
      <div className="img_banner_wrapper">
        <img src={img} alt="banner"/>
      </div>

    </div>
    );
  }
}

import React, { Component } from "react";

import antispam from "../../../assets/img/png/antispam4.png";
import icon from "../../../assets/img/checked.svg";
import Auditoria from "./Auditoria";
import Controle from "./Controle";
import Backup from "./Backup";
import Recibo from "./Recibo";
import $ from "jquery";
import Propriedades from "./Propriedades";

export default class Funcionalidades extends Component {
  componentDidMount() {
    var $doc = $("html, body");

    $("a").click(function () {
      $doc.animate(
        {
          scrollTop: $($.attr(this, "href")).offset().top,
        },
        1000
      );
      return false;
    });
  }

  render() {
    return (
      <div className="funcinalidades_container">
        <div className="funcionalidades_wrapper">
          <div className="title_funcionalidades_wrapper">
            <div className="title_wrapper">
              <h1>Funcionalidades</h1>
              <div className="line"></div>

              <div className="funcionalidades_icon_wrapper">
                <div className="func">
                  <img src={icon} width="20%" alt="mail" />
                  <a href="#antispam">
                    <div className="icon_funcinalidades scrollSuavel">
                      MAV Antispam
                    </div>
                  </a>
                </div>

                <div className="func">
                  <img src={icon} width="20%" alt="mail" />
                  <a href="#auditoria">
                    <div className="icon_funcinalidades">Arquivamento</div>
                  </a>
                </div>

                <div className="func">
                  <img src={icon} width="20%" alt="mail" />
                  <a href="#controle">
                    <div className="icon_funcinalidades">
                      Controle de Acesso
                    </div>
                  </a>
                </div>

                <div className="func">
                  <img src={icon} width="20%" alt="mail" />
                  <a href="#backup">
                    <div className="icon_funcinalidades">Backup</div>
                  </a>
                </div>

                <div className="func">
                  <img src={icon} width="20%" alt="mail" />
                  <a href="#recibo">
                    <div className="icon_funcinalidades">Recibo de Entrega</div>
                  </a>
                </div>

                <div className="func">
                  <img src={icon} width="20%" alt="mail" />
                  <a href="#calaboracao">
                    <div className="icon_funcinalidades">
                      Colaboração e Produtividade
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="products_wrapper_description" id="antispam">
            <h1 className="title_products">MAV Antispam</h1>
            <div className="line"></div>
            <div className="description_products">
              Garanta 99,9% de eficácia na proteção contra spams, fraudes e
              phishing (roubo de informações) que chegam por e-mail diariamente.
              Para isso, são feitas análises de textos do e-mail, black list e
              assinaturas geradas automaticamente. O bloqueio desses spams além
              de garantir a segurança também aumenta a produtividade, evitando
              perda de tempo com e-mails que não são o foco do seu negócio.
            </div>
          </div>

          <img className="imagem_products rec" src={antispam} alt="mail" />
        </div>

        <Auditoria />
        <Controle />
        <Backup />
        <Recibo />
        <Propriedades />
      </div>
    );
  }
}

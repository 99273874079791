import React, { Component } from 'react';
import Banner from '../../Components/Banner';
import Products from './Products';
// import Call from './Call';
// import $ from 'jquery';


export default class Home extends Component {
  render() {
    return (
        <div className="home_wrapper">
            <Banner/>
            <Products/>
        </div>
    );
  }
}
